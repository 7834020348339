<script>
import Swal from 'sweetalert2'
import VTextbox from '../components/Textbox.vue'
import VButton from '../components/Button.vue'
import VCards from '../components/Cards.vue'
import VSteps from '../components/Steps.vue'
// import bancos from '../assets/bancos.json'

export default {
  name: 'BankTransfer',
  components: {
    VTextbox,
    VButton,
    VCards,
    VSteps,
  },
  data() {
    return {
      name: '',
      saldo: null,
      idContaCartaoDebitado: null,
      docIdCpfCnpjEinSSN: '',
      bankId: '',
      type: 'F',
      agency: '',
      agencyDigit: '',
      account: '',
      accountDigit: '',
      value: '',
      accountType: 'CC',
      description: '',
      bancos: {},
      card: {},
      rateValue: 5,
      showTransferCards: false
    }
  },
  methods: {
    sendForm() {
      if (this.$store.state.formLoading) return false
      this.$store.commit('formLoading', true)
      this.$validator.validateAll().then(() => {
        this.value = Number(this.value);
        this.idContaCartaoDebitado = Number(this.idContaCartaoDebitado);
        this.bankId = String(this.bankId);

        const {
          type,
          docIdCpfCnpjEinSSN,
          name,
          bankId,
          agency,
          agencyDigit,
          account,
          accountDigit,
          accountType,
          description,
          idContaCartaoDebitado,
          value,
          saldo,
          rateValue
        } = this;

        if (value > (saldo - rateValue)) {
          this.$store.commit('message', {
            type: 'error',
            text: 'Saldo insuficiente'
          })
          this.$store.commit('formLoading', false);
          Swal.fire({
            type: 'error',
            title: 'Saldo insuficiente',
            text: `Operação tarifada, ao realizar transferências desconte a tarifa da TED no valor de ${(rateValue || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}.`,
            confirmButtonText: 'Voltar',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
          })
          return false
        }
        // TODO: remover essa validação deixar o backend validar
        let maxValue = 10000;
        if (this.isPjCard()) {
          maxValue = 50000;
        }
        // if (value < 10) {
        //   Swal.fire({
        //     type: 'error',
        //     title: 'Valor mínimo de R$10,00',
        //     text: 'Por favor, informe um valor maior que R$10,00.',
        //   })
        //   this.$store.commit('formLoading', false)
        //   return false
        // }
        if (value < 10) {
          this.$store.commit('message', {
            type: 'error',
            text: 'Valor mínimo de R$10,00.'
          })
          this.$store.commit('formLoading', false);
          return false
        }

        if (value > maxValue) {
          this.$store.commit('message', {
            type: 'error',
            text: 'Valor da transferência acima do limite do seu cartão.'
          })
          this.$store.commit('formLoading', false);
          return false
        }
        const postData = {
          value,
          description,
          idContaCartaoDebitado,
          beneficiary: {
            type,
            docIdCpfCnpjEinSSN: docIdCpfCnpjEinSSN.replace(/\D/g, ''),
            name,
            bankId,
            agency,
            agencyDigit,
            account: account.replace(/[^\dx]+/g, ''),
            accountDigit: accountDigit.replace(/[^\dx]+/g, ''),
            accountType
          }
        }

        if (!postData.value || !postData.description || !postData.idContaCartaoDebitado
        || !postData.beneficiary.type || !postData.beneficiary.docIdCpfCnpjEinSSN
        || !postData.beneficiary.name || !postData.beneficiary.bankId
        || !postData.beneficiary.agency || !postData.beneficiary.account
        || !postData.beneficiary.accountDigit || !postData.beneficiary.accountType) {
          Swal.fire({
            title: 'Erro ao realizar transferência',
            text: 'Verifique os dados, é necessário preencher todos os campos',
            type: 'error',
            confirmButtonText: 'Ok'
          })
          this.$store.commit('formLoading', false);
          return false
        }

        this.$http.post('/transfers/transfers', postData)
          .then(() => {
            this.$store.commit('formLoading', false)
            // if (data.statusTransacao > 0 || !data) {
            //   Swal.fire(
            //     'Erro ao realizar transferência',
            //     data.message || 'Verifique os dados e tente novamente',
            //     'error'
            //   )
            //   return false
            // }
            Swal.fire({
              html: `
                <div>
                  <span class="material-symbols-outlined" style="font-size:3.5rem; color:#7066e0;">check_circle</span>

                  <p style="line-height:21px; margin-top: 15px; font-size: 18px;">
                    <strong>Sua transação está em análise.</strong>
                    </br>
                    <strong>Consulte em suas despesas</strong>
                    </br> 
                    <strong>se realmente foi concluída.</strong>
                  </p>
                </div>
              `,
              confirmButtonText: 'Ok',
              confirmButtonColor: '#7066e0'
            })
            this.name = ''
            this.idContaCartaoDebitado = null
            this.docIdCpfCnpjEinSSN = ''
            this.bankId = ''
            this.type = 'F'
            this.agency = ''
            this.agencyDigit = ''
            this.account = ''
            this.accountDigit = ''
            this.value = ''
            this.accountType = 'CC'
            this.description = ''
          }, (err) => {
            let message = null;

            if (typeof err === 'string') message = err;
            else message = (err && err.message)

            message = message || 'Houve um erro ao realizar a transferência, tente novamente mais tarde.';

            this.$store.commit('message', {
              type: 'error',
              text: message
            });
            this.$store.commit('formLoading', false);
          })
      })
    },
    isPjCard() {
      return (this.card.idProduto === 4 || this.card.idProduto === 5 || this.card.idProduto === 6);
    },
    nextStep(card, balance) {
      // console.log(balance)
      this.card = card;
      if (card.idProduto === 2 || card.idProduto === 10 || card.idProduto === 5) {
        this.$store.commit('message', {
          type: 'error',
          text: 'Este cartão não permite transferencias ou saques, somente compras.'
        })
        return false
      }

      if (balance === 0) {
        this.$store.commit('message', {
          type: 'error',
          text: 'O cartão selecionado não tem saldo.'
        })
        return false
      }

      this.saldo = balance
      this.idContaCartaoDebitado = card.idConta.toString()
    },
    async getBanks() {
      await this.$http.get('/bankList/listarBankList')
        .then((res) => {
          this.bancos = res.data;
        }).catch((err) => {
          Swal.fire({
            type: 'error',
            title: err.message,
            confirmButtonText: 'Ok',
            customClass: {
              title: 'px-5'
            },
          })
        })
    },
    async permitedOperation() {
      await this.$http.get('/utils/check-restricao')
        .catch((data) => {
          // console.log('data', data)
          // show message and redirect to home
          Swal.fire({
            type: 'error',
            title: data.message || 'Transação não permitida nesse horário',
            confirmButtonText: 'Ok',
            customClass: {
              title: 'px-5'
            },
          }).then(() => {
            this.$router.push('/')
          }).finally(() => {
            this.$router.push('/')
          })
        })
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    cards() {
      return this.$store.state.cards;
    },
    availableCards() {
      return this.$store.state.availableCards;
    }
  },
  async mounted() {
    await this.permitedOperation();
    await this.getBanks();

    if (this.card.idProduto !== 2 && this.card.idProduto !== 5 && this.card.idProduto !== 8 && this.card.idProduto !== 10 && (this.card.idStatusCartao !== 2 && this.card.idStatusCartao !== 3 && this.card.idStatusCartao !== 4 && this.card.idStatusCartao !== 5 && this.card.idStatusCartao !== 6 && this.card.idStatusCartao !== 7 && this.card.idStatusCartao !== 8 && this.card.idStatusCartao !== 9 && this.card.idStatusCartao !== 10 && this.card.idStatusCartao !== 11 && this.card.idStatusCartao !== 12 && this.card.idStatusCartao !== 13 && this.card.idStatusCartao !== 14 && this.card.idStatusCartao !== 15 && this.card.idStatusCartao !== 16 && this.card.idStatusCartao !== 17)) {
      this.showTransferCards = true;
    } else {
      this.showTransferCards = false;
    }
  },
  //   Swal.fire({
  //     html: `
  //       <div style='text-align: left;color: #6c757d!important; font-size: medium;'>
  //         <h3 style='font-size: 1.75rem;'>Olá!</h3>
  //           Operação não disponível no momento. Agradecemos a compreensão.
  //           <br />
  //           <br />
  //           <br />
  //           <center>Equipe OnlyPay</center>
  //       </div>
  //     `,
  //     type: 'warning',
  //     showConfirmButton: true,
  //     confirmButtonText: 'Voltar',
  //     allowOutsideClick: false,
  //     allowEscapeKey: false,
  //     allowEnterKey: false
  //   })
  //   this.$router.push('/')
  // }
  // mounted() {
  //   Swal.fire(
  //     'AVISO!',
  //     `<p style="text-align:left;">
  //     Caro Usuário,<br><br>
  //     Site disponível para consulta de saldo e desbloqueio do seu cartão.<br><br>
  //     Demais funcionalidades como pagamento de boleto, transferência entre cartões, transferência e recarga de celular estarão disponíveis em breve.<br><br>
  //     Equipe OnlyPay.
  //     </p>`,
  //     'info'
  //   )
  // }
  /*
  mounted() {
    this.type = (!this.user.cnpj ? 'fisico' : 'juridico');
    this.docIdCpfCnpjEinSSN = (this.user.cpf ? this.user.cpf : this.user.cnpj);
    this.name = this.user.nome;
  }
  */
}
</script>

<template>
  <div class="wrap">
    <v-steps :current="idContaCartaoDebitado ? 2 : 1" :length="2" class="transfer__header"/>
    <div class="mt-1 pb-1">
      <p>
        Para realizar a transferência preencha o formulário com os dados da conta bancária e da pessoa que receberá o valor. Confira todos os dados antes de enviar.
        <br>
        <br>
        ATENÇÃO: Transferências realizadas em dias úteis até às 14h59
        ficarão disponíveis até às 17h do mesmo dia. Transferências realizadas após às 15h, os valores ficarão
        disponíveis no próximo dia útil.
        <br>
        <br>
        Essa transferência é tarifada no valor de R$ 5,00 por transação e será descontada do saldo do seu cartão.
        Para realizar essa transação o saldo mínimo precisa ser de R$10,00.
        <br>
        <br>
        A partir das 20h até 8h do próximo dia útil não haverá processamento.
        <br>
        <br>
      </p>
    </div>
    <div v-if="!idContaCartaoDebitado">
      <p v-if="this.availableCards"><strong>Selecione o cartão que deseja que seja debitado o valor da transferência.</strong></p>
      <p v-else class="unavailableCards"><strong>Não há cartões disponíveis para este tipo de transação.</strong></p>
      <br>
      <v-cards @click="nextStep" :showActions="false" :showBalance="true"/>
    </div>
    <form v-if="idContaCartaoDebitado" @submit.prevent="sendForm" class="form-charge">
      <br>
      <label class="input-type">
        <input v-model="type" type="radio" name="type" value="F">
        Pessoa Física
      </label>
      <label class="input-type">
        <input v-model="type" type="radio" name="type" value="J">
        Pessoa Jurídica
      </label>

      <div class="row">
        <div class="col-md-6">
          <v-textbox
            v-model="name"
            data-vv-as="name"
            data-vv-scope="name"
            label="Nome do beneficiário:"
            name="name"
            type="text"
            validate="required"
            :maxlength="30"
            required/>
        </div>
        <div class="col-md-6">
          <v-textbox
            v-model="docIdCpfCnpjEinSSN"
            data-vv-as="docIdCpfCnpjEinSSN"
            data-vv-scope="docIdCpfCnpjEinSSN"
            :label="`${type === 'F' ? 'CPF' : 'CNPJ'} do beneficiário:`"
            name="docIdCpfCnpjEinSSN"
            :mask="type === 'F' ? '###.###.###-##' : '##.###.###/####-##'"
            type="text"
            required/>
        </div>
      </div>
      <div class="textbox">
        <label class="textbox__label">Selecione o banco de destino:</label>
        <select v-model="bankId" :disabled="bancos.length === 0" class="textbox__input" required>
          <option value="">{{bancos.length === 0 ? 'Preencha o telefone' : 'Selecione' }}</option>
          <option
            :value="banco.code"
            v-for="banco in bancos"
            :key="banco.IdBank">
            {{`${banco.code} - ${banco.name}`}}
          </option>
        </select>
      </div>
      <div class="row">
        <div class="col-8">
          <v-textbox
            v-model="agency"
            data-vv-as="agency"
            data-vv-scope="agency"
            label="Numero da agencia:"
            :mask="'##############'"
            name="agency"
            type="text"
            required/>
        </div>
        <!-- <div class="col-3">
          <v-textbox
            v-model="agencyDigit"
            data-vv-as="agencyDigit"
            data-vv-scope="agencyDigit"
            label="Dígito:"
            :mask="'##############'"
            name="agencyDigit"
            :maxlength="2"
            type="text"
            />
        </div> -->
      </div>
      <div class="row">
        <div class="col-8 row pr-0 align-items-end">
          <div class="col-8">
            <v-textbox
              v-model="account"
              data-vv-as="account"
              data-vv-scope="account"
              label="Numero da conta:"
              :mask="'##############'"
              name="account"
              type="text"
              required/>
          </div>
          <div class="col-4 pr-0">
            <v-textbox
              v-model="accountDigit"
              data-vv-as="accountDigit"
              data-vv-scope="accountDigit"
              label="Dígito:"
              name="accountDigit"
              :maxlength="2"
              type="text"
              required
              />
          </div>
        </div>
      </div>
      <label class="input-type">
        <input v-model="accountType" type="radio" name="accountType" value="CC">
        Conta corrente
      </label>
      <label class="input-type">
        <input v-model="accountType" type="radio" name="accountType" value="SA">
        Conta poupança
      </label>
      <div class="row">
        <div class="col-sm-6">
          <v-textbox
            v-model="value"
            label="Valor do título:"
            data-vv-as="Valor do título"
            required
            money
            name="valor"
            validate="required"
            />
        </div>
      </div>
      <v-textbox
        v-model="description"
        data-vv-as="description"
        data-vv-scope="description"
        label="Descrição:"
        name="description"
        :maxlength="20"
        type="text"
        required
        />
        <label class="terms-check">
          <input type="checkbox" title="Confirmo os dados para transferência" required>
          Confirmo todos dados para a transferência bancária
        </label>
      <v-button label="Transferir" class="block__form__actions__submit"/>
    </form>
  </div>
</template>

<style lang="postcss" scoped>

.unavailableCards {
  color: red;
}
.terms-check{
  padding-top: 20px;
  display: block;
  input{
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
}
p{
  small{
    font-size: 12px;
    display: block;
    line-height: 15px;
    margin: 15px 0;
    font-weight: bold;
  }
}
.row{
  margin-bottom: 15px;
}
.input-type{
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 30px;
  input{
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.block__form__actions__submit{
  margin-top: 2rem;
}
.wrap{
  position: relative;
  @media (max-width: 991px) {
    background-color: #fff;
    /* padding-top: 5vh; */
  }
  .tax {
    padding-top: 31px;
    display: block;
    color: red;
  }
}
.form-charge{
  background-color: #fff;
  padding-top: 2rem;
  padding-bottom: 5rem;
  max-width: 500px;
  @media (min-width: 992px) {
    padding-top: 0;
    max-width: 100%;
  }
}
</style>
